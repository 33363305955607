<template>
  <nav class="app-top-nav">
    <div class="container">
      <ul>
        <template v-if="login.token">
          <li>
            <RouterLink to="">
              <i class="iconfont icon-user"></i>
              {{ login.name }}
            </RouterLink>
            <!-- <RouterLink to="/member/nominal/home">
              <i class="iconfont icon-user"></i>{{ user.profile.account }}
            </RouterLink> -->
          </li>
          <li>
            <a href="javascript:" style="color: #d12b23" @click="logout()">
              安全退出
            </a>
          </li>
        </template>
        <template v-else>
          <li>
            <RouterLink to="/login">请先登录</RouterLink>
          </li>
          <li>
            <router-link to="/register">
              免费注册
            </router-link>
          </li>
        </template>
        <li>
          <RouterLink to="/">齐鲁首页</RouterLink>
        </li>

                <li>
                    <div v-if="login.status < 4">
                        <RouterLink to="/user/nominalominalhome">用户中心</RouterLink>
                    </div>
                    <div v-else-if="login.status">
                        <RouterLink to="/user/home">用户中心</RouterLink>
                    </div>
										<div v-else>
											 <RouterLink to="/login">用户中心</RouterLink>
										</div>
                </li>

        <li>
          <!-- <a href="javascript:"><i class="iconfont icon-phone"></i>手机版</a> -->
          <img src="../../static/icons/iphone.png" alt="" />
          <span style="color: #d12b23; font-weight: 800">
            热线电话: 400-8889-6588
          </span>
        </li>
      </ul>
    </div>
  </nav>
</template>
<script>
// 导入vuex
import { useStore } from 'vuex'
// import { useRouter } from 'vue-router'

export default {
  name: 'AppTopNav',

  computed: {
    storeUserInfoValue () {
      return this.$store.state.login.userInfo
    },
  },
  created () {
    this.init()
  },
  methods: {
    init () {
    },
    logout () {
      this.$store
        .dispatch('OutLogin')
        .then((response) => {
          if (response.returnCode === '1') {
            this.$store.commit('login/userInfo', {}) // 清空用户信息
            this.$router.push('/login').then(() => {
              // instance.$message({ type: 'success', text: '退出成功' })
            })
          }
        })
        .catch(() => {})
    },
  },
  setup () {
    // 使用vuex
    const store = useStore()
    // 使用router
    // const router = useRouter()
    // 获取用户信息
    const login = store.state.login.userInfo
    // console.log('login:', login)
    // const logout = (instance) => {
    //     store.commit('login/userInfo', {}) // 清空用户信息
    //     router.push('/login').then(() => {
    //         instance.$message({ type: 'success', text: '退出成功' })
    //     })
    // }

    return {
      login,
      // logout,
    }
  },
}
</script>
<style scoped lang="less">
.app-top-nav {
  background: #f5f5f5;
  height: 40px;

  ul {
    display: flex;
    height: 38px;
    justify-content: flex-end;
    align-items: center;
    li {
      a {
        padding: 0 15px;
        color: #000000;
        line-height: 1;
        font-size: 12px;
        display: inline-block;
        i {
          font-size: 12px;
          margin-right: 2px;
        }
        &:hover {
          color: @xtxColor;
        }
      }
      ~ li {
        a {
          border-left: 1px solid #666;
        }
      }
    }
  }
}
</style>
