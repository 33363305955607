<template>
<div>
  <header class="app-header">
    <div class="container">
      <h1 class="logo">
        <RouterLink to="/">
  <img src="http://file.qltrade.ixiaoguo.com.cn/file/4255933719577558.png" />

				</RouterLink>
      </h1>
      <slot name="headerRight"/>
    </div>
    <div class="line"></div>
  </header>
  <div class="main-content">
    <slot/>
  </div>
  </div>

</template>

<script>
export default {
  name: 'AppLayoutCommon',
}
</script>

<style scoped lang="less">
.app-header {
  background: #fff;
  .container {
    display: flex;
    align-items: center;
  }
  .logo {
    width: 200px;
		  img {
      display: block;
      height: 110px;
      width: 100%;
      text-indent: -9999px;
			padding: 10px;
    }
    // a {
    //   display: block;
    //   height: 132px;
    //   width: 100%;
    //   text-indent: -9999px;
    //   background: url(../../static/icons/log.png) no-repeat center 18px /
    //     contain;
    // }
  }
}

.line {
  height: 4px;
  background: #d12b23;
}
.main-content {
  width: 1240px;
  margin: 0 auto;
}
</style>
