<template>
  <div>
    <AppTopNav></AppTopNav>
    <AppLayoutCommon>
      <PageHeader title="返回" back hidden-breadcrumb @click="onBack()" />
      <div style="padding: 10px">
        <div class="info-box">
          <div class="title">
            <span class="icon"></span>
            <span class="text">填写收货地址</span>
          </div>
          <Form
            ref="formData"
            :model="formData"
            :rules="ruleValidate"
            :label-width="80"
          >
            <Row :gutter="80">
              <Col span="12">
                <FormItem label="详细地址" prop="addressDetail">
                  <Input
                    v-model="formData.addressDetail"
                    placeholder="请填写详细地址"
                  ></Input>
                </FormItem>
              </Col>
              <Col span="12">
                <FormItem label="收货单位" prop="receiveUnit">
                  <Input
                    v-model="formData.receiveUnit"
                    placeholder="请填写收货单位"
                  ></Input>
                </FormItem>
              </Col>
            </Row>
            <!-- <br> -->
            <Row :gutter="80">
              <Col span="12">
                <FormItem label="手机号码" prop="tel">
                  <Input
                    v-model="formData.tel"
                    placeholder="请填写手机号码"
                    type="tel"
                  ></Input>
                </FormItem>
              </Col>
              <Col span="12">
                <FormItem label="固定电话" prop="">
                  <Input
                    v-model="formData.phone"
										type="number"
                    placeholder="请填写固定电话"
                  ></Input>
                </FormItem>
              </Col>
            </Row>
            <Row :gutter="80">
              <Col span="12">
                <FormItem label="联系人" prop="name">
                  <Input
                    v-model="formData.name"
                    placeholder="请填写联系人"
                  ></Input>
                </FormItem>
              </Col>
              <Col span="12"></Col>
            </Row>
          </Form>
        </div>

        <div class="info-box">
          <div class="title">
            <span class="icon"></span>
            <span class="text">供应商信息</span>
          </div>
          <dl>
            <dt>供应商</dt>
            <dd>{{ orderInfo.merchantStore.businessName }}</dd>
            <dt>公司地址</dt>
            <dd>{{ orderInfo.merchantStore.address }}</dd>
          </dl>
          <dl>
            <dt>联系电话</dt>
            <dd>{{ orderInfo.merchantStore.contactPhone }}</dd>
            <!-- <dt>联系人</dt>
            <dd>李三明</dd> -->
          </dl>
        </div>

        <div class="info-box">
          <div class="title">
            <span class="icon"></span>
            <span class="text">交易方式</span>
          </div>
          <dl>
            <dt>发货地</dt>
            <dd>{{ orderInfo.merchantStoreProduct.sendFromCityCode }}</dd>
            <dt>服务方式</dt>
            <dd>自提/快递</dd>
          </dl>
          <dl>
            <dt>交易模式</dt>
            <dd>
              <div v-if="orderInfo.merchantStoreProduct.tradeType === '1'">
                全款模式
              </div>
              <div v-else>保证金模式</div>
            </dd>
          </dl>
        </div>
        <!-- 商品信息 -->
        <div class="title">
          <span class="icon"></span>
          <span class="text">商品信息</span>
        </div>
        <div class="member-order">
          <div class="body">
            <div class="column goods">产品型号</div>
            <!-- <div class="column state">仓库</div> -->
            <div class="column amount">单价</div>
            <div class="column state">数量/重量</div>
            <div class="column action">总金额</div>
          </div>
        </div>
        <div class="order-item">
          <div class="body">
            <div class="column goods">
              <ul>
                <li>
                  <!-- <RouterLink class="image" :to="`/goods/${order.id}`">
                    <img :src="order.img" />
                  </RouterLink> -->
                  <div class="info">
                    <p class="name ellipsis-2">
                      <!-- {{orderInfo.}} -->
                      {{ orderInfo.merchantStoreProduct.productName }}
                    </p>
                    <p class="attr ellipsis">
                      <span>
                        材质：{{ orderInfo.merchantStoreProduct.material }}
                      </span>
                      <span>
                        规格：{{ orderInfo.merchantStoreProduct.formate }}
                      </span>
                    </p>
                    <p class="attr ellipsis">
                      <span>
                        {{ orderInfo.merchantStoreProduct.sendFromCityCode }}
                      </span>
                    </p>
                  </div>
                </li>
              </ul>
            </div>
            <div class="column amount">
              <p class="red">
                ¥
                <Numeral
                  :value="orderInfo.merchantStoreProduct.activePrice"
                  format="0,0.00"
                ></Numeral>
                /{{ orderInfo.merchantStoreProduct.unitName }}
              </p>
            </div>
            <div class="column state">
              <!-- <p>{{ order.warehouseName }}</p> -->
              <p class="green">
                {{ orderInfo.count }}
                {{ orderInfo.merchantStoreProduct.unitName }}
              </p>
            </div>
            <div class="column amount">
              <!-- <p>{{ order.warehouseName }}</p> -->
              <p class="red">
                ¥
                <Numeral
                  :value="
                    orderInfo.merchantStoreProduct.activePrice * orderInfo.count
                  "
                  format="0,0.00"
                ></Numeral>
              </p>
            </div>
          </div>
        </div>

        <!-- 汇总信息 -->
        <div class="sum-info">
          <div class="info">
            <div class="item">
              <label>
                货品总计 (不含运费)： ¥
                <Numeral
                  :value="
                    orderInfo.merchantStoreProduct.activePrice * orderInfo.count
                  "
                  format="0,0.00"
                ></Numeral>
              </label>
            </div>
          </div>
        </div>

        <div class="sum-info1">
          <div v-if="orderInfo.merchantStoreProduct.tradeType === '1'">
            <Button class="btn" @click="submit('formData')">提交订单</Button>
          </div>
          <div v-else>
            <Button class="btn" @click="pay('formData')">支付保证金</Button>
          </div>

          <span class="hint">
            已选 1 个产品型号，总计 (不含运费)：
            <span class="price">
              ￥
              <Numeral
                :value="
                  orderInfo.merchantStoreProduct.activePrice * orderInfo.count
                "
                format="0,0.00"
              ></Numeral>
            </span>
          </span>
        </div>
      </div>
    </AppLayoutCommon>
    <AppFooter />
  </div>
</template>

<script>
import AppLayoutCommon from '@/components/AppLayoutCommon'
import AppFooter from '@/components/AppFooter'
import AppTopNav from '@/components/AppTopNav'
import VueEvent from '@/model/event'
import { ref } from 'vue'
export default {
  data () {
    return {
      formData: {
        addressDetail: '',
        receiveUnit: '',
        tel: '',
        phone: '',
        name: '',
        goodsId: '',
        quantity: '',
        deliveryDate: '',
      },
      orderInfo: {
        merchantStore: {
          businessName: '',
          address: '',
          contactPhone: '',
        },
        merchantStoreProduct: {
          productName: '',
          sendFromCityCode: '',
          tradeType: '1',
          material: '',
          formate: '',
          inventory: '',
          unitName: '',
          activePrice: '',
          visitCount: '',
          minSaleCount: 1,
        },
      },
      ruleValidate: {
        addressDetail: [
          {
            required: true,
            message: '详细地址不能为空',
            trigger: 'blur',
          },
					{ type: 'string', min: 10, message: '请输入详细地址', trigger: 'blur' }
        ],
        receiveUnit: [
          {
            required: true,
            message: '收货单位不能为空',
            trigger: 'blur',
          },
        ],
        tel: [
          {
            required: true,
            message: '手机号码不能为空',
            trigger: 'blur',
          },
					 { pattern: /^1[3456789]\d{9}$/, message: '手机号码格式不正确', trigger: 'blur' }
        ],
        name: [
          {
            required: true,
            message: '联系人不能为空',
            trigger: 'blur',
          },
        ],
      },
    }
  },

  components: {
    AppLayoutCommon,
    AppTopNav,
    AppFooter,
  },

  created () {},
  mounted () {
    this.qlCheckoutMitt()
  },
  methods: {
    submit (name) {
      const postData = ref({
        addressDetail: this.formData.addressDetail,
        receiveUnit: this.formData.receiveUnit,
        tel: this.formData.tel,
        phone: this.formData.phone,
        name: this.formData.name,
        goodsId: this.orderInfo.merchantStoreProduct.id,
        quantity: this.orderInfo.count,
        deliveryDate: '',
      })
      this.$refs[name].validate((valid) => {
        if (valid) {
          this.$store
            .dispatch('SubmitCategoryOrder', postData.value)
            .then((response) => {
              this.nameList = response.result
            })
            .then(() => {

              this.$router.push({
                path: '/qlcheckout/submitsuc',
                query: {
                  purchaser: this.nameList.result.purchaser,
                  supplier: this.nameList.result.supplier,
                  tradeId: this.nameList.result.tradeId,
                },
              })
            })
            .catch(() => {})
        } else {
          this.$Message.error('请填写正确的信息')
        }
      })
    },
    pay (name) {
      const postData = ref({
        addressDetail: this.formData.addressDetail,
        receiveUnit: this.formData.receiveUnit,
        tel: this.formData.tel,
        phone: this.formData.phone,
        name: this.formData.name,
        goodsId: this.orderInfo.merchantStoreProduct.id,
        quantity: this.orderInfo.count,
        deliveryDate: '',
      })
      this.$refs[name].validate((valid) => {
        if (valid) {
          this.$Spin.show({
            render: (h) => {
              return h('div', [
                h(
                  'div',
                  {
                    style: {
                      color: '#d12b23',
                      fontSize: '22px',
                    },
                  },
                  '正在启动银行支付请不要离开页面。否则无法完成支付...',
                ),
              ])
            },
          })

          this.$store
            .dispatch('PaymentSubmitWithGuaranteeOrder', postData.value)
            .then((res) => {
              // this.$Spin.hide()
              if (res.returnCode === '1') {
                if (res.result.authUrl) {
                  window.open(res.result.authUrl, '_self')
                }
              }
            })
            .then(() => {})
        } else {
          this.$Message.error('请填写正确的信息')
        }
      })
    },
    onBack () {
      this.$router.go(-1)
    },
    qlCheckoutMitt () {
      VueEvent.on('qlCheckoutMitt', (value) => {
        this.orderInfo = value
      })
    },
  },

  destoryed () {
    VueEvent.all.clear()
  },
}
</script>

<style lang="less">
// 信息盒子
.info-box {
  border: 1px solid #dcdfe6;
  padding: 10px;
  margin-bottom: 10px;
  dl {
    display: flex;
    line-height: 30px;
    margin-bottom: 10px;
    dt {
      width: 80px;
      color: #999;
      font-size: 14px;
      i {
        display: inline-block;
        width: 0.5em;
      }
    }
    dd {
      flex: 1;
    }
  }
}
.title {
  height: 20px;
  line-height: 1;
  margin-bottom: 10px;
  padding: 25px 5px;
  .icon {
    display: inline-block;
    height: 20px;
    width: 5px;
    background-color: #d12b23;
    margin-right: 10px;
    vertical-align: middle;
  }
  .text {
    display: inline-block;
    vertical-align: middle;
    font-size: 16px;
    font-weight: 600;
  }
}
// 汇总信息
.sum-info {
  // padding: 0 30px 10px 30px;
  overflow: hidden;
  .btn {
    // float: right;
    width: 144px;
    background: #d12b23;
    height: 37px;
    font-weight: 400;
    font-size: 16px;
    color: #fff;
    text-align: center;
    line-height: 40px;
    border: 1px solid #cfcdcd;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .info {
    // width: 300px;
    // height: 160px;
    float: right;
    font-size: 14px;

    .item {
      line-height: 40px;
      display: flex;
      label {
        text-align: right;
        // width: 160px;
        // font-size: 14px;
        i {
          display: inline-block;
          width: 2em;
        }
      }
      span {
        flex: 1;
        text-align: right;
        padding-right: 30px;
        &.price {
          font-size: 20px;
          color: @priceColor;
        }
      }
    }
  }
}

.sum-info1 {
  // padding: 0 30px 10px 30px;
  overflow: hidden;

  .hint {
    float: right;
    text-align: center;
    height: 37px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    // color: #dc9100;
  }
  .price {
    font-size: 20px;
    color: @priceColor;
  }
  .btn {
    float: right;
    width: 134px;
    background: #d12b23;
    height: 37px;
    font-weight: 400;
    font-size: 15px;
    color: #fff;
    text-align: center;
    line-height: 40px;
    border: 1px solid #cfcdcd;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .info {
    width: 300px;
    // height: 160px;
    float: right;
    font-size: 14px;

    .item {
      line-height: 40px;
      display: flex;
      label {
        text-align: right;
        width: 160px;
        // font-size: 14px;
        i {
          display: inline-block;
          width: 2em;
        }
      }
      span {
        flex: 1;
        text-align: right;
        padding-right: 30px;
        &.price {
          font-size: 20px;
          color: @priceColor;
        }
      }
    }
  }
}
.member-order {
  height: 100%;
  background: #fff;
  position: relative;
  .body {
    display: flex;
    align-items: stretch;
    background: #f5f5f5;
    .column {
      border-left: 1px solid #f5f5f5;
      text-align: center;
      padding: 20px;
      > p {
        padding-top: 10px;
      }
      &:first-child {
        border-left: none;
      }
      &.goods {
        flex: 2;
        padding: 10;
        align-self: center;
        text-align: left;
      }
      &.state {
        // width: 200px;
        flex: 1;
        .green {
          color: @xtxColor;
        }
      }
      &.amount {
        flex: 1;
        .red {
          color: @priceColor;
        }
      }
      &.action {
        // width: 140px;
        flex: 1;
        a {
          display: block;
          &:hover {
            color: @xtxColor;
          }
        }
      }
    }
  }
}
.order-item {
  margin-bottom: 20px;
  border: 1px solid #f5f5f5;

  .body {
    display: flex;
    align-items: stretch;
    .column {
      border-left: 1px solid #f5f5f5;
      text-align: center;
      padding: 20px;
      > p {
        padding-top: 10px;
      }
      &:first-child {
        border-left: none;
      }
      &.goods {
        flex: 2;
        padding: 0;
        align-self: center;
        ul {
          li {
            border-bottom: 1px solid #f5f5f5;
            padding: 10px;
            display: flex;
            &:last-child {
              border-bottom: none;
            }
            .image {
              width: 70px;
              height: 70px;
              border: 1px solid #f5f5f5;
            }
            .info {
              width: 220px;
              text-align: left;
              padding: 0 10px;
              p {
                margin-bottom: 5px;
                &.name {
                  height: 38px;
                }
                &.attr {
                  color: #999;
                  font-size: 12px;
                  span {
                    margin-right: 5px;
                  }
                }
              }
            }
            .price {
              width: 100px;
            }
            .count {
              width: 80px;
            }
          }
        }
      }
      &.state {
        flex: 1;
        text-align: center;
        align-self: center;
        .green {
          color: #333;
          font-size: 14px;
          span {
            margin-right: 5px;
          }
        }
      }
      &.amount {
        flex: 1;
        text-align: center;
        align-self: center;
        .red {
          color: @priceColor;
        }
      }
      &.action {
        flex: 1;
        a {
          display: block;
          &:hover {
            color: @xtxColor;
          }
        }
      }
    }
  }
}
</style>
